<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="config.isDetailDisplay = false">返回</el-button></p>  
                <div class="h-b"><el-button type="primary" size="small" @click="add" v-right-code="'Productbom:Empty'">新增</el-button></div>
                <div class="h-b"><el-button type="primary" size="small" @click="save" v-right-code="'Productbom:Edit'">保存</el-button></div>
            </div>
        </div>
        <el-tabs type="border-card" v-model="activeName">
            <el-tab-pane label="基本信息" name="tabbasic">
                <div>
                    <el-form :model="dataSource" ref="_bomForm" :rules="bomValidateRule">
                        <el-form-item>
                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>组合商品编码：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="ProductCombineNo">
                                    <el-input v-model="dataSource.ProductCombineNo" :maxlength="30" :minlength="2" placeholder=""></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>组合商品名称：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="ProductCombineName">
                                    <el-input v-model="dataSource.ProductCombineName" :maxlength="50" placeholder="组合商品名称"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">
                                规格：
                            </el-col>
                            <el-col :span="7">
                                <el-form-item prop="SKU">
                                    <el-input v-model="dataSource.SKU" :maxlength="30" :minlength="2" placeholder="规格"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">
                                &nbsp;
                            </el-col>
                            <el-col :span="7">
                                &nbsp;
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>
                <Accessories :dataSource="dataSource.ChildSkus"></Accessories>
            </el-tab-pane>
        </el-tabs>
        
    </div>
</template>

<script>
    export default {
        data() {
            return {
                activeName: 'tabbasic',
                isDisabled: false,
                disableEdit: true,
                bomValidateRule: {
                    ProductCombineName: [
                        { required: true, message: '请输入组合商品名称', trigger: 'blur' }
                    ]
                },
                isPassValidate: true,
                currentSelectedRow: {},
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
            },
            dataSource: {
            }
        },
        watch: {
            dataSource: {
                handler(curVal, oldVal) {
                    this.isDisabled = this.Utils.emptyGuid != this.dataSource.CombineID;
                },
                deep: true
            },
            ProductStatus(curVal, oldVal) {
                this.disableEdit = this.dataSource.ProductStatus != 0;
            }
        },
        computed: {
            ProductStatus() {
                return this.dataSource.ProductStatus;
            }
        },
        mounted: function () {
            this.Event.$on("clearForm", this.resetForm);
        },
        methods: {
            add: function () {
                this.Event.$emit("t.bom.onAdd");
                this.toggleTabToFirst();
            },
            toggleTabToFirst: function () {
                $("div.el-tabs__item:first", $("div.el-tabs__nav", this.$el)).trigger("click");
            },
            save: function () {
                //var isValid = true;
                //this.dataSource.ChildSkus.forEach(item => item.RowEdit && (isValid = false));
                //if (!isValid) {
                //    this.Utils.messageBox("组件信息未确认.", "info");
                //    return false;
                //}

                this.$refs["_bomForm"].validate((valid) => {
                    this.isPassValidate = valid;
                    if (valid) {
                        var obj = this.Utils.cloneObj(this.dataSource);
                        var routeName = this.dataSource.CombineID == '0' ? "add" : "edit";
                        this.$ajax.send("omsapi/productbom/" + routeName, "post", obj, (data) => {
                            this.dataSource.CombineID === this.Utils.emptyGuid && (this.dataSource.CombineID = data.Result);
                            this.$parent.syncDataSource();
                            this.Event.$emit("t.bom.onSave", data.Result);
                            this.Utils.messageBox("保存成功.", "success");
                        });
                    } else {
                        return false;
                    }
                });
            },
            resetForm: function () {
                !this.isPassValidate && this.$refs['_bomForm'].resetFields && this.$refs['_bomForm'].resetFields();//清空表单
            }
        },
        components: {
            "Accessories": resolve => { require(['../components/accessories.vue'], resolve) },
        }
    }
</script>
<style scoped>
    .center {
        text-align: center !important;
    }

    .left {
        text-align: left !important;
    }

    .t_w {
        width: 100%;
    }

        .t_w tr td {
            padding: 4px 8px;
        }
</style>